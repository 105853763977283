<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
</ul>
<h1>Packing Record</h1>
<ng-container *ngIf="dataLoaded">
   
        
        <div class="row">
            <div class="cell-5">
                <label>Pallet Reference </label>
                <input id="palletID"  [(ngModel)]="palletRef" class="myInputs" autocomplete="off" data-role="input">
            </div>
        </div>

        <div class="row">
            <div class="cell-5">
                <button class="button primary" (click)="GetFormData()">Search</button>
            </div>
        </div>
        
        <ng-container *ngIf="dataLoaded1">
            <form>
            <div class="row">
                <div class="cell-4">
                    <div class="row">
                    <label><B>Status</B></label>
                    </div>
                    <div class="row">
                    <label>{{status}}</label>
                    </div>
                </div>
                <div class="cell-4">
                    <div class="row"> 
                        <label><B>Actual Pallet ID</B></label>
                    </div>
                    <div class="row"> 
                    <label>{{palletRef}} </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="cell-4">
                    <div class="row">
                    <label><B>WO Reference</B></label>
                    </div>
                    <div class="row">
                    <label>{{selectedWO}}</label>
                    </div>
                </div>
                <div class="cell-4">
                    <div class="row"> 
                        <label><B>Part</B></label>
                    </div>
                    <div class="row"> 
                    <label>{{partDesc}} </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="cell-5">
                    <label>Location of Origin (Bin or Flaker)</label>
                    <select data-role="select" id="System"   [(ngModel)]="selectedValue" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let c of locations" [ngValue]="c.code">{{c.description}}</option>
                    </select>
                </div> 
             
            </div>

            <div class="row">
                <div class="cell-5">
                    <label>No of Bags</label>
                    <input id="NoOfBags" type="number" class="metro-input" value = "0">
                </div>
    
                <div class="cell-5">
                    <label>Weight</label>
                    <input id="weight" type="number" class="metro-input" value = "0.00">
                </div>
            </div>
            <div class="row">
                <div class="cell-5">
                    <label>Jordans Pallet No</label>
                    <input id="ContainerID" type="text" class="metro-input" >
                </div>
            </div>
        
            <ng-container *ngIf="alreadyComplete">
            <div class="row">
                <div class="cell">
                    <button class="button primary" (click)="saveFormData()">Save</button>
                </div>
            </div>
            </ng-container>
        </form>
        </ng-container>
        
 </ng-container>