import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-repack',
  templateUrl: './repack.component.html',
  styleUrl: './repack.component.css'
})
export class RepackComponent {
  dataLoaded = false;
  
  palletRef : string = '';

  status : any;
  woOrder : any;
  partID : any;
  partDesc : any;
  warehouse : any;
  location : any;
  batchNo : any;
  qty : any;
  locations : any;
  selectedValue ="";
  canContinue : any;
  dbstatus : any;

  private sm : SharedModule;
  
  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
    this.canContinue = false;
  }
  public GetFormData()
  {
    
    let counter : number = 0;
    let formData:FormData = new FormData();
    this.palletRef = $('#palletID').val();


    if (this.palletRef.startsWith('{'))
    {
      if (this.palletRef.endsWith('}'))
        this.palletRef = this.palletRef.substring(2, this.palletRef.length -1);
      else
        this.palletRef = this.palletRef.substring(2, this.palletRef.length);
    }

    
    
    let params = new HttpParams().set("sPalletID",this.palletRef != null ? this.palletRef : "")
    this.http.get(this.baseUrl + 'Milling/GetPalletInfo', {params:params} ).subscribe(
      (data:any) => {
         this.canContinue = true;
         this.status = data[0].status;
         this.woOrder = data[0].woOrder;
         this.partID = data[0].partid;
         this.partDesc = data[0].partdesc;
         this.warehouse = data[0].warehouseid;
         this.location = data[0].locationid;
         this.batchNo = data[0].batchnumber;
         this.dbstatus = data[0].dbstatus;
         this.qty = Number(data[0].quantity);
         counter++;

          if (this.qty == 0 && this.status == "found")
          {
              this.status = "Pallet has already been used";
              this.canContinue = false;
          }
          else
          if (this.warehouse != "REPACK")
          {
            this.status = "The Pallet is within the wrong warehouse";
             this.canContinue = false;
          }
          else
          if (this.dbstatus != "PHYSICAL")
            {
              this.status = "This pallet is not in physical status";
              this.canContinue = false;
           }
           


         this.dataLoaded = counter == 2;
       });
   
       this.http.get(this.baseUrl + 'Milling/GetListRepackLocation', {params:params} ).subscribe(
        (data:any) => {
          this.locations = data; 
          this.selectedValue =""; 
           counter++;
           this.dataLoaded = counter == 2;
         });

       
         
  }


  public saveFormData()
  {
    let formData:FormData = new FormData();
    formData.set("EventSource",'RIPTIP');
    formData.set("Warehouse",'WIPB1');
    formData.set("Location",this.location != '' ? this.location : '');
    formData.set("Weight",this.qty != '' ? this.qty : 0);
    formData.set("PartID",this.partID != '' ? this.partID : '');
    formData.set("PartDesc",this.partDesc != '' ? this.partDesc : '');
    formData.set("WorksOrder",this.woOrder != '' ? this.woOrder : '');
    formData.set("BatchID",this.batchNo != '' ? this.batchNo : '');
    formData.set("PalletID",this.palletRef != '' ? this.palletRef : '');
    formData.set("LocationOn",this.selectedValue != '' ? this.selectedValue : '');
    
    
      this.http
        .post(this.baseUrl + 'Navara/RipAndTip', formData)
        .subscribe({
          next: (response) => this.saveFinished(),
          error: (error) => this.sm.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
        });
      //this.reload();  

    


  }


  saveFinished()
  {
    this.sm.metroAlert("Save succesful.","Title");
    this._router.navigate(['/'])
  }

}
