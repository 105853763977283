<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/milling/testing/grn']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>GRN</a></li>
    <li><a [routerLink]="['/milling/testing/weighbridge']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Weighbridge</a></li>
    <li><a [routerLink]="['/milling/testing/startstop']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Start / Stop</a></li>
    <li><a [routerLink]="['/milling/testing/relocation']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Stock Relocation</a></li>
    <li><a [routerLink]="['/milling/testing/packingline']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Packing Line</a></li>
    <li><a [routerLink]="['/milling/testing/stockadjust']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Stock Adjust</a></li>
    <li><a [routerLink]="['/milling/testing/weightoff']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Weight Off</a></li>
    <li><a [routerLink]="['/milling/testing/weighton']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Weight On</a></li>
    <li><a [routerLink]="['/milling/testing/weightonoff']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Weight On & Off</a></li>
</ul>

<div>

    <h1>Welcome to the Middleware Test Bed</h1>
    <p>Select an option from the menu at the top of the screen.</p>
    
   
</div>