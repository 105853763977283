import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
//import { environment } from './environment/environment';


export function getBaseUrl() {
  //Navara
  //return "http://10.100.10.51:81/";
  //CEMEX
  return "https://railportal.individual-systems.net:4331/";
  //Camgrain - Live
  //return "https://camgrain.individual-systems.net:4331/";
  //Camgrain - Test
  //return "http://camgraintest.individual-systems.net:81/";

  //This now comes from the environment folder.
  // return environment.apiUrl;
  //Local Testing
  //return "http://localhost:5000/";
  
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
