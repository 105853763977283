<div class="grid">
    <div class="row">
        <div class="cell-2">
            <ul class="v-menu border bd-light" data-role="ripple" data-ripple-target="li">
                <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Home</a></li>
                <li><a [routerLink]="['/milling/activeConnections']" [routerLinkActive]="['active']"><span class="mif-tools icon"></span> Active Connections</a></li>
                <li><a [routerLink]="['/milling/analysisTest']" [routerLinkActive]="['active']"><span class="mif-microscope icon"></span> Analysis Records</a></li>
                <li><a [routerLink]="['/milling/location']" [routerLinkActive]="['active']"><span class="mif-tools icon"></span> Locations</a></li>
                <li><a [routerLink]="['/milling/shiftRecords']" [routerLinkActive]="['active']"><span class="mif-assignment icon"></span> Shift Records</a></li>
                <li><a [routerLink]="['/milling/packingData']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Packing Records</a></li>
                <li><a [routerLink]="['/milling/ripandtip']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Rip and Tip</a></li>
                <li><a [routerLink]="['/milling/repack']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Repack</a></li>
                
                
                <li><a [routerLink]="['/milling/plantData']" [routerLinkActive]="['active']"><span class="mif-database icon"></span> Plant Data</a></li>
                <li><a href="#" data-hotkey="Alt+F1"><span class="mif-chart-line icon"></span> Reports</a>
                    <ul class="v-menu" data-role="dropdown">
                        <li><a [routerLink]="['/milling/reports/dayreport']" [routerLinkActive]="['active']"><span class="mif-chart-line icon"></span>Day Report</a></li>
                        <!--
                        <li><a [routerLink]="['/reportviewer/dayreport']" [routerLinkActive]="['active']"><span class="mif-chart-line icon"></span>Day Report</a></li>
                        <li><a [routerLink]="['/reportviewer/siloreport']" [routerLinkActive]="['active']"><span class="mif-chart-line icon"></span>Silo Report</a></li>
                        <li><a [routerLink]="['/reportviewer/shiftreport1']" [routerLinkActive]="['active']"><span class="mif-chart-line icon"></span>Shift Report (Cleaning)</a></li>
                        <li><a [routerLink]="['/reportviewer/shiftreport2']" [routerLinkActive]="['active']"><span class="mif-chart-line icon"></span>Shift Report (Processing)</a></li>
                        <li><a [routerLink]="['/reportviewer/shiftreport3']" [routerLinkActive]="['active']"><span class="mif-chart-line icon"></span>Shift Report (Bin Levels)</a></li>
                        -->
                    </ul>
                </li>
                <li><a [routerLink]="['/milling/testing/home']" [routerLinkActive]="['active']"><span class="mif-tools icon"></span> Testing</a></li>
            </ul>
        </div>
        
        <div class="cell-6">
            <div>
                <h1>Navara Middleware</h1>
                <p>Welcome to the middleware application pages.</p>

                <p>Please select a Menu option from the left of the page. To return here, select "Home" on any page.</p>

            </div>
        </div>
        <div class="cell-2">
            <div>
                

            </div>
        </div>
    </div>
</div>
