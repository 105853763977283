import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SharedModule} from "../../shared/shared.module";
import {Router} from "@angular/router";

@Component({
  selector: 'app-switch-user',
  templateUrl: './switch-user.component.html',
  styleUrls: ['./switch-user.component.css']
})
export class SwitchUserComponent {
  _imitateUser: string = "";
  authenticateduser: any;
  currentuser: any;
  allMembers: any;
  dataLoaded: boolean = false;
    constructor(private http: HttpClient,@Inject('BASE_URL')private baseUrl: string,private router: Router,public sharedFunctions: SharedModule) {
  }

  ngOnInit() {
    this.http.get<any>(this.baseUrl + 'Grain/AvailableMembers').subscribe({
      next: (result: any) => {
        this.allMembers = result.members;
        this.authenticateduser = result.authUser;
        //this.authenticateduser = result.currentUser;
        this.currentuser = result.currentUser;
        this.dataLoaded = true;
      },
      error: (error: any) => {

      }
    });

  }

  imitateUser(login:string) {

    let formData:FormData = new FormData();
    formData.append("memberId",login);
    this._imitateUser = login;
    this.http
      .post(this.baseUrl + 'Grain/Auction/SwitchMember', formData)
      .subscribe({
        next: (response) => this.completeRequest(this._imitateUser),
        error: (error) => this.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
      });
  }

  private completeRequest(imitateUser:string) {
      localStorage.setItem("imitateUser",imitateUser)
      this.router.navigate(["/"]);
  }

  endImitate() {
    localStorage.setItem("imitateUser","");
    this.router.navigate(["/"]);
  }

  shouldHighlight(item: any) {
    return item.memberextid === this.currentuser.memberextid;
  }
}
