<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
</ul>
<h1>Repack</h1>
<ng-container >
    <form>
        <div class="row">
            <div class="cell-5">
                <label>Pallet Reference </label>
                <input id="palletID"  [(ngModel)]="palletRef" class="myInputs" autocomplete="off" data-role="input">
            </div>
        </div>

        <div class="row">
            <div class="cell-5">
                <button class="button primary" (click)="GetFormData()">Search</button>
            </div>
        </div>

        <ng-container *ngIf="dataLoaded">
            <div class="row">
                <div class="cell-4">
                    <div class="row">
                    <label><B>Status</B></label>
                    </div>
                    <div class="row">
                    <label>{{status}}</label>
                    </div>
                </div>
                <div class="cell-4">
                    <div class="row"> 
                        <label><B>Actual Pallet ID</B></label>
                    </div>
                    <div class="row"> 
                    <label>{{palletRef}} </label>
                    </div>
                </div>
                
            </div>
        
        <div class="row">
            <div class="cell-4">
                <div class="row">
                <label><B>Works Order </B></label>
                </div>
                <div class="row">
                <label>{{woOrder}}</label>
                </div>
            </div>

            <div class="cell-4">
                <div class="row"> 
                    <label><B>Batch ID</B></label>
                </div>
                <div class="row"> 
                <label>{{batchNo}} </label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="cell-4">
                <div class="row">
                <label><B>Warehouse </B></label>
                </div>
                <div class="row">
                <label>{{warehouse}}</label>
                </div>
            </div>

            <div class="cell-4">
                <div class="row"> 
                    <label><B>Location</B></label>
                </div>
                <div class="row"> 
                <label>{{location}} </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="cell-4">
                <div class="row">
                <label><B>Batch Status </B></label>
                </div>
                <div class="row">
                <label>{{dbstatus}}</label>
                </div>
            </div>

            
        </div>

        <div class="row">
            <div class="cell-4">
                <div class="row">
                <label><B>Part ID </B></label>
                </div>
                <div class="row">
                <label>{{partID}}</label>
                </div>
            </div>

            <div class="cell-4">
                <div class="row"> 
                    <label><B>Part Desc</B></label>
                </div>
                <div class="row"> 
                <label>{{partDesc}} </label>
                </div>
            </div>
        </div>

        
        <ng-container *ngIf="canContinue">
        <div class="row">
            <div class="cell-5">
                <label>Destination of Tipped Product</label>
                <select data-role="select" id="System"   [(ngModel)]="selectedValue" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let c of locations" [ngValue]="c.code">{{c.description}}</option>
                </select>
            </div>
        </div>



        <div class="row">
            <div class="cell">
                <button class="button primary" (click)="saveFormData()">Tip Product</button>
            </div>
        </div>
        </ng-container>

      </ng-container>


       
    </form>
</ng-container>
