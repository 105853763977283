import { Component } from '@angular/core';

@Component({
  selector: 'app-shiftrecords',
  templateUrl: './shiftrecords.component.html',
  styleUrls: ['./shiftrecords.component.css']
})
export class ShiftrecordsComponent {
  dataLoaded = false;
}
