
  

<ul class="v-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li class="menu-title">General</li>
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Home</a></li>
</ul>
<ng-container *ngIf="!dataLoaded">
  <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
      data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
  </ng-container>

<ng-container *ngIf="dataLoaded">
    <div class="row">
        <div class="cell flex-align-self-start">
          <div class="row flex-align-end">
            <div class="row">
              <div class="cell"><h4>Train Details</h4></div>
              
              <div class="container">
                <div class="grid">
                    <div class="row">
                        <div class="cell-3"><div>Arrival Date</div></div> <div class="cell-3"><div><input type="Date" [(ngModel)]="arrivaldate" [ngModelOptions]="{standalone: true}"></div></div> 
                        <div class="cell-3"><div>Arrival Time</div></div> <div class="cell-3"><div><input type= "time" [(ngModel)]="arrivaltime" [ngModelOptions]="{standalone: true}"></div></div> 
                    </div>
                    
                    <div class="row"><div class="cell-3"><div>Departure Date</div></div> <div class="cell-3"><div><input type="date" [(ngModel)]="departuredate" [ngModelOptions]="{standalone: true}"></div></div> 
                    <div class="cell-3"><div>Departure Time</div></div> <div class="cell-3"><div><input type="time" [(ngModel)]="departuretime" [ngModelOptions]="{standalone: true}"> </div></div> </div>
                    
                </div>
              </div>
             

              <div class="container">
                <!--                //<table class="table striped table-border mt-4 subcompact  " id="openTrains" data-role="table" data-pagination="false" data-show-search="false" data-rows="-1" data-show-rows-steps="false" data-show-pagination="false" data-show-items-steps="false" data-table-info-title="false">
                //  <thead>
                //    <tr>
                //    <th data-size="2" >Seq</th>
                //    <th data-size="5">Wagon ID</th>
                 //   <th data-size="200">Product</th>
                //    <th data-size="5">Weight</th>
                //    <th >Reason</th>
                //    </tr>
                //  </thead>
                //  <tbody row-hover >
                  
                    
               
                 // <tr *ngFor="let c of wagonData">
                 //     <td >{{c.seq}}</td><td >{{c.wagonID}}</td><td>{{c.product}}</td><td>{{c.weight}}</td>
                 //     <td><div class="container"><select data-role="select"  (change)="onChange($event.target)" id=Math.random().toString(36).substring(2)><option *ngFor="let r of reasons" [ngValue]="r.code">{{r.desc}}</option></select></div></td>
                 //   </tr>
                 // </tbody>
                //</table>
                -->
                <ng-container >
                  <div class="row" >
                    <div class="cell-1">
                       
                        <label><B>Seq</B></label>
                       
                    </div>
                    <div class="cell-2">
                            <label><B>Wagon ID</B></label>
                       
                    </div>
                    <div class="cell-3">
                      <label><B>Product</B></label>
                 
                    </div>
                    <div class="cell-1">
                     
                      <label><B>Weight</B></label>
                     
                  </div>
                    <div class="cell-5">
                      <label><B>Reason</B></label>
                    </div>
                  </div>
                  </ng-container>
                <ng-container *ngFor="let c of wagonData">
                <div class="row" >
                  <div class="cell-1">
                     
                      <label><B>{{c.seq}}</B></label>
                     
                  </div>
                  <div class="cell-2">
                          <label>{{c.wagonID}}</label>
                     
                  </div>
                  <div class="cell-3">
                    <label>{{c.product}}</label>
               
                  </div>
                  <div class="cell-1">
                     
                    <label>{{c.weight}}</label>
                   
                </div>
                  <div class="cell-5">
                      <select data-role="select"  (change)="onChange(c)" id="c.seq" [(ngModel)]="c.reason" [ngModelOptions]="{standalone: false}"><option *ngFor="let r of reasons" [ngValue]="r.code">{{r.desc}}</option></select>
                   
                  </div>
                </div>
                </ng-container>
                <div>
                  <label>Comments</label>
                  <input id="ContainerID" type="text" class="metro-input" rows="4" cols="50" [(ngModel)]="comment" [ngModelOptions]="{standalone: true}">
              </div>
      
            </div>
            <div class="row">
              <div class="cell">
                  <button style="width:600px" class="button primary" (click)="saveFormData()">Save</button>
              </div>
            </div>    
            </div>
            
          </div>
        </div>
        
        
        
    </div>
</ng-container>

<ng-container *ngIf="order == '2'">
 <!-- <div class="row">
    <div class="cell flex-align-self-start">
      <div class="row flex-align-end">
        <div class="row">
          <div class="cell"><h4>Train Details</h4></div>
          <div class="container">
            <div class="grid">
                <div class="row no-gap"><div class="cell-3"><div>Arrival Date</div></div> <div class="cell-3"><div><input data-role="datepicker" data-year="false"></div></div> </div>
                <div class="row no-gap"><div class="cell-3"><div>Arrival Time</div></div> <div class="cell-3"><div><input data-role="timepicker"></div></div> </div>
                <div class="row no-gap"><div class="cell-3"><div>Departure Time</div></div> <div class="cell-3"><div><input data-role="timepicker"></div></div> </div>
                
              </div>
            </div>
          </div>
          <div class="row"></div>
           <div class="container">
           
            <table class="table striped table-border mt-4 subcompact  " id="simpleloads" data-role="table" data-show-search = "false" 	data-table-info-title="false" data-pagination="false" data-show-search="false" data-show-entries="false">
              <thead>
                <tr>
                <th>Sequence</th>
                <th>Wagon Number</th>
                <th>Wagon Weight</th>
                <th>Product</th>
                <th>Reason</th>
                <th>Comments</th>
                
                </tr>
              </thead>
              <tbody row-hover >
                <tr>
                  <td>1</td>
                  <td>709156</td>
                  <td>102</td>
                  <td>10MM DUST</td>
                  <td><select data-role="select" id="System">
                    <option class="fg-cyan"></option>
                    <option class="fg-green"></option>
                    <option class="fg-green"></option>
                    <option class="fg-green"></option>
                </select></td>
                  <td><input type="text"></td>
                  
                </tr>
                <tr>
                    <td>2</td>
                    <td>7091578</td>
                    <td>99.8</td>
                    <td>10MM DUST</td>
                    <td><select data-role="select" id="System">
                      <option class="fg-cyan"></option>
                      <option class="fg-green"></option>
                      <option class="fg-green"></option>
                      <option class="fg-green"></option>
                  </select></td>
                    <td><input type="text"></td>
                    
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>709159</td>
                    <td>106</td>
                    <td>10MM DUST</td>
                    <td><select data-role="select" id="System">
                      <option class="fg-cyan"></option>
                      <option class="fg-green"></option>
                      <option class="fg-green"></option>
                      <option class="fg-green"></option>
                  </select></td>
                    <td><input type="text"></td>
                    
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>709151</td>
                    <td>95.8</td>
                    <td>10MM DUST</td>
                    <td><select data-role="select" id="System">
                      <option class="fg-cyan"></option>
                      <option class="fg-green"></option>
                      <option class="fg-green"></option>
                      <option class="fg-green"></option>
                  </select></td>
                    <td><input type="text"></td>
                    
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>709152</td>
                    <td>101.5</td>
                    <td>10MM DUST</td>
                    <td><select data-role="select" id="System">
                      <option class="fg-cyan"></option>
                      <option class="fg-green"></option>
                      <option class="fg-green"></option>
                      <option class="fg-green"></option>
                  </select></td>
                    <td><input type="text"></td>
                  </tr>
              </tbody>
            </table>
          </div></div>
        </div>
      
        <div class="row">
          <div class="cell">
              <button class="button primary"  >Save</button>
              </div>
              <div class="cell">
              <button class="button primary"  >Confirm Train Complete</button>
          </div>
      </div>
    
    </div>
    
    
    
</div>
    
    
  -->
</ng-container>

