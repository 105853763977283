import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {User} from "./user";

import {Router} from "@angular/router";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;
  public roles:string[] = ['Admin'];
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,private router: Router) {
    if (this.getJWT()){
      let jwt:string = this.getJWT() ?? "";
      this.currentUserSubject = new BehaviorSubject<User | null>(JSON.parse(jwt));
    }else{
      this.currentUserSubject = new BehaviorSubject<User | null>(null);
    }

    this.currentUser = this.currentUserSubject.asObservable();


  }

  public getJWT() : string | null
  {
    return localStorage.getItem('currentUser');
  }


  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  public get isLoggedIn() : boolean {
    let ret = false;
    if (this.currentUserSubject.value ? true : false){
      // @ts-ignore
       ret = (this.currentUserValue?.success)
      // @ts-ignore
    //  ret = ret && !(this.currentUserValue.selectMember ?? true);
    }
    
    //ret = this.currentUserSubject.value ? true : false
    return ret;
  }

  login(username: string, password: string) {
    return this.http.post<any>(this.baseUrl + 'Authentication/Login', { username, password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  changePassword(password: string, passwordValid: string) {
    return this.http.post<any>(this.baseUrl + 'MemberContent/ChangePassword', { password, passwordValid })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  selectMember(member: string) {
    return this.http.post<any>(this.baseUrl + 'MemberContent/SelectMember', { member })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("imitateUser");
    localStorage.removeItem('currentUser');
    //localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

}




